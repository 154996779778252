import React from "react";
import Navbar from "../components/Navbar";

const Artwork = () => {
  return (
    <main>
      <Navbar />
      Artwork
    </main>
  );
};

export default Artwork;
